<template>
  <div class="">
    <div class="switch-btn">
      <div :class="value =='1' ? 'left-btn active' : 'left-btn'" @click="$emit('change', '1')">{{ $t('refinancing.Approve') }}</div>
      <div :class="value =='2' ? 'right-btn active' : 'right-btn'" @click="$emit('change', '2')">{{ $t('refinancing.Decline') }}</div>
    </div>
  </div>

</template>

<script>
export default {
  components: {},
  props: {
    value: { type: String, default: '1' }
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.switch-btn {
  border-radius: 50px;
  height: 56px;
  position: relative;
  background-color: #f5f5f5;
  margin: 10px 10px ;
  border: 8px solid #f5f5f5;
  font-size: 12px;
  .left-btn, .right-btn {
    width: 52%;
    height: 100%;
    line-height: 40px;
    border-radius: 40px;
    position: absolute;
    top: 0;
    text-align: center;
  }
  .left-btn {
    left: 0;
  }
  .right-btn {
    right: 0;
  }
  .active {
    background-color: #fff;
    color: #000;
  }
}
</style>
