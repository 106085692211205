export const approve = {
  'schema': {
    'type': 'object',
    'title': 'create form',
    'description': '',
    'properties': {},
    'required': []
  },
  'columns': [
    {
      'name': '',
      'schema': {
        'type': 'object',
        'description': '自定义对象组',
        'properties': {},
        'required': []
      },
      'fieldJson': {
        'noteStyle': {},
        'tipTitleStyle': {},
        'tipContentStyle': {},
        'extraConfig': {},
        'required': true,
        'show': true,
        'tipShowType': 'auto',
        'isCustom': true,
        'ocrSwitch': []
      },
      'renderType': 'Object',
      'columns': [
        {
          'name': 'Contract Number',
          'schema': {
            'type': 'string',
            'description': '自定义文本输入框'
          },
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'suffix': '',
            'defaultValueSource': 1,
            'displayRelyGather': [],
            'defaultValueOriginParams': [],
            'defaultValueOriginCfg': {
              'method': 'POST'
            },
            'isCustom': true,
            'extendDisplayCfg': [],
            'placeholder': '',
            'placeholderLocal': '',
            'filter': 'amount'
          },
          'renderType': 'Input',
          'key': 'contractNo',
          'renderKey': '1733397522441_89987',
          'nameLocal': 'Contract Number'
        },
        {
          'name': 'Actual Principal Debt',
          'schema': {
            'type': 'integer',
            'description': '自定义多行文本输入框'
          },
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'defaultValueSource': 1,
            'displayRelyGather': [],
            'defaultValueOriginParams': [],
            'defaultValueOriginCfg': {
              'method': 'POST'
            },
            'isCustom': true,
            'extendDisplayCfg': [],
            'placeholder': '',
            'placeholderLocal': '',
            'filter': 'amount'
          },
          'renderType': 'Numeric',
          'key': 'actualPrincipleDebt',
          'renderKey': '1733397194473_98507',
          'nameLocal': 'Actual Principal Debt'
        },
        {
          'name': 'Actual Total Loan Disbursed',
          'schema': {
            'type': 'integer',
            'description': '自定义多行文本输入框'
          },
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'defaultValueSource': '1',
            'displayRelyGather': [],
            'defaultValueOriginParams': [],
            'defaultValueOriginCfg': {
              'method': 'POST'
            },
            'isCustom': true,
            'extendDisplayCfg': [],
            'suffixType': '2',
            'prefixType': '1',
            'filter': 'amount',
            'prefixBuilt': []
          },
          'renderType': 'Numeric',
          'key': 'actualLoanDisbursed',
          'renderKey': '1733449152053_3454',
          'nameLocal': 'Actual Total Loan Disbursed'
        },
        {
          'name': 'Actual Tenure',
          'schema': {
            'type': 'integer',
            'description': '自定义多行文本输入框',
            'maximum': 4,
            'minimum': 1
          },
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'defaultValueSource': 1,
            'displayRelyGather': [],
            'defaultValueOriginParams': [],
            'defaultValueOriginCfg': {
              'method': 'POST'
            },
            'isCustom': true,
            'extendDisplayCfg': [],
            'filter': 'amount'
          },
          'renderType': 'Numeric',
          'key': 'tenure',
          'renderKey': '1733449390319_58203',
          'nameLocal': 'Actual Tenure'
        },
        {
          'name': 'Actual Installment',
          'schema': {
            'type': 'integer',
            'description': '自定义多行文本输入框'
          },
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'defaultValueSource': 1,
            'displayRelyGather': [],
            'defaultValueOriginParams': [],
            'defaultValueOriginCfg': {
              'method': 'POST'
            },
            'isCustom': true,
            'extendDisplayCfg': [],
            'filter': 'amount'
          },
          'renderType': 'Numeric',
          'key': 'installmentAmount',
          'renderKey': '1733449439346_78363',
          'nameLocal': 'Actual Installment'
        }
      ],
      'key': 'container',
      'renderKey': '1733397163794_83306'
    },
    {
      'name': '',
      'key': 'additionalInfo',
      'schema': {
        'type': 'object',
        'description': '补充信息',
        'properties': {},
        'required': []
      },
      'renderType': 'Object',
      'columns': [
        {
          'name': '',
          'nameLocal': '',
          'key': 'attachments',
          'schema': {
            'type': 'array',
            'description': '附件集合'
          },
          'columns': [
            {
              'name': '',
              'nameLocal': '',
              'schema': {
                'type': 'object',
                'description': '附件',
                'properties': {},
                'required': []
              },
              'renderType': 'Object',
              'fieldJson': {
                'noteStyle': {},
                'tipTitleStyle': {},
                'tipContentStyle': {},
                'extraConfig': {},
                'required': true,
                'show': true,
                'tipShowType': 'auto',
                'isArrayItem': true,
                'ocrSwitch': [],
                'requiredMessage': '',
                'requiredMessageLocal': ''
              },
              'columns': [
                {
                  'name': 'Contract',
                  'schema': {
                    'type': 'array',
                    'minItems': 1,
                    'description': '自定义上传控件'
                  },
                  'fieldJson': {
                    'noteStyle': {},
                    'tipTitleStyle': {},
                    'tipContentStyle': {},
                    'extraConfig': {},
                    'required': true,
                    'show': true,
                    'tipShowType': 'auto',
                    'maxCount': '',
                    'uploadTarget': 'oss',
                    'uploadSource': 3,
                    'defaultValueSource': 1,
                    'displayRelyGather': [],
                    'defaultValueOriginParams': [],
                    'defaultValueOriginCfg': {
                      'method': 'POST'
                    },
                    'isCustom': true,
                    'extendDisplayCfg': [],
                    'uploadType': [
                      'pdf',
                      'img'
                    ]
                  },
                  'renderType': 'Upload',
                  'key': 'contracts',
                  'renderKey': '1733449949214_83241',
                  'nameLocal': 'Contract'
                }
              ],
              'key': '1733449507085_21402',
              'renderKey': '1733449507085_21402'
            }
          ],
          'renderType': 'Array',
          'renderKey': '1733449507085_98504',
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'repeat': false,
            'ocrSwitch': []
          }
        }
      ],
      'renderKey': '1733449507085_72728',
      'fieldJson': {
        'noteStyle': {},
        'tipTitleStyle': {},
        'tipContentStyle': {},
        'extraConfig': {},
        'required': true,
        'show': true,
        'tipShowType': 'auto',
        'ocrSwitch': []
      }
    }
  ],
  'fieldJson': {
    'version': '2.7.0',
    'hasClause': true,
    'clauseContent': 'I agree to the agreement that GIC is only liable in accordance with the terms & conditions and exclusions of Circular No. 22/2016 / TT-BTC by Ministry of Finance for Compulsory Insurance Civil liability of motor vehicle owners (for products Compulsory civil liability insurance for passengers on motorbike) or Voluntary Insurance Rules of motor vehicle issued by decision No. 0246/2015 / QD-GIC-XCG dated March 12, 2015 by General Director of GIC (for personal accident insurance product on motorbike)',
    'clauseContentLocal': 'Tôi đồng ý và thoả thuận rằng, Bảo Hiểm Toàn Cầu chỉ chịu trách nhiệm phù hợp với những điều khoản, điều kiện và điểm loại trừ của Thông tư số 22/2016/TT-BTC quy định Quy tắc Bảo hiểm Bắt buộc Trách nhiệm dân sự của chủ xe cơ giới của Bộ Tài chính (đối với sản phẩm Bảo hiểm trách nhiệm dân sự bắt buộc người ngồi trên xe máy) hoặc Quy tắc Bảo hiểm tự nguyện xe cơ giới ban hành theo quyết định số 0246/2015/QĐ-GIC-XCG ngày 12/03/2015 của Tổng giám đốc Tổng Công ty Cổ phần Bảo hiểm Toàn Cầu (đối với sản phẩm Bảo hiểm tai nạn người ngồi trên xe máy)',
    'styleVersion': 'V2'
  },
  'config': {
    'labelWidth': 200
  }
}

export const decline = {
  'schema': {
    'type': 'object',
    'title': 'create form',
    'description': '',
    'properties': {},
    'required': []
  },
  'columns': [
    {
      'name': '',
      'schema': {
        'type': 'object',
        'description': '自定义对象组',
        'properties': {},
        'required': []
      },
      'fieldJson': {
        'noteStyle': {},
        'tipTitleStyle': {},
        'tipContentStyle': {},
        'extraConfig': {},
        'required': true,
        'show': true,
        'tipShowType': 'auto',
        'isCustom': true,
        'ocrSwitch': []
      },
      'renderType': 'Object',
      'columns': [
        {
          'name': 'Decline Reason',
          'schema': {
            'type': 'string',
            'description': '自定义多行文本输入框'
          },
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'rows': 3,
            'isCustom': true,
            'extendDisplayCfg': []
          },
          'renderType': 'Textarea',
          'key': 'declineReason',
          'renderKey': '1733469015660_46178',
          'nameLocal': 'Decline Reason'
        }
      ],
      'key': 'container',
      'renderKey': '1733397163794_83306'
    },
    {
      'name': '',
      'key': 'additionalInfo',
      'schema': {
        'type': 'object',
        'description': '补充信息',
        'properties': {},
        'required': []
      },
      'renderType': 'Object',
      'columns': [
        {
          'name': '',
          'nameLocal': '',
          'key': 'attachments',
          'schema': {
            'type': 'array',
            'description': '附件集合'
          },
          'columns': [
            {
              'name': '',
              'nameLocal': '',
              'schema': {
                'type': 'object',
                'description': '附件',
                'properties': {},
                'required': []
              },
              'renderType': 'Object',
              'fieldJson': {
                'noteStyle': {},
                'tipTitleStyle': {},
                'tipContentStyle': {},
                'extraConfig': {},
                'required': true,
                'show': true,
                'tipShowType': 'auto',
                'isArrayItem': true,
                'ocrSwitch': []
              },
              'columns': [
                {
                  'name': 'Contract',
                  'schema': {
                    'type': 'array',
                    'minItems': 1,
                    'description': '自定义上传控件'
                  },
                  'fieldJson': {
                    'noteStyle': {},
                    'tipTitleStyle': {},
                    'tipContentStyle': {},
                    'extraConfig': {},
                    'required': true,
                    'show': true,
                    'tipShowType': 'auto',
                    'maxCount': '',
                    'uploadTarget': 'oss',
                    'uploadSource': 3,
                    'defaultValueSource': 1,
                    'displayRelyGather': [],
                    'defaultValueOriginParams': [],
                    'defaultValueOriginCfg': {
                      'method': 'POST'
                    },
                    'isCustom': true,
                    'extendDisplayCfg': [],
                    'uploadType': [
                      'pdf',
                      'img'
                    ],
                    'contentCutting': false,
                    'cuttingContentFields': []
                  },
                  'renderType': 'Upload',
                  'key': 'contracts',
                  'renderKey': '1733449949214_83241',
                  'nameLocal': 'Contract'
                }
              ],
              'key': '1733449507085_21402',
              'renderKey': '1733449507085_21402'
            }
          ],
          'renderType': 'Array',
          'renderKey': '1733449507085_98504',
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'repeat': false,
            'ocrSwitch': []
          }
        }
      ],
      'renderKey': '1733449507085_72728',
      'fieldJson': {
        'noteStyle': {},
        'tipTitleStyle': {},
        'tipContentStyle': {},
        'extraConfig': {},
        'required': true,
        'show': true,
        'tipShowType': 'auto',
        'ocrSwitch': []
      }
    }
  ],
  'fieldJson': {
    'version': '2.7.0',
    'hasClause': true,
    'clauseContent': 'I agree to the agreement that GIC is only liable in accordance with the terms & conditions and exclusions of Circular No. 22/2016 / TT-BTC by Ministry of Finance for Compulsory Insurance Civil liability of motor vehicle owners (for products Compulsory civil liability insurance for passengers on motorbike) or Voluntary Insurance Rules of motor vehicle issued by decision No. 0246/2015 / QD-GIC-XCG dated March 12, 2015 by General Director of GIC (for personal accident insurance product on motorbike)',
    'clauseContentLocal': 'Tôi đồng ý và thoả thuận rằng, Bảo Hiểm Toàn Cầu chỉ chịu trách nhiệm phù hợp với những điều khoản, điều kiện và điểm loại trừ của Thông tư số 22/2016/TT-BTC quy định Quy tắc Bảo hiểm Bắt buộc Trách nhiệm dân sự của chủ xe cơ giới của Bộ Tài chính (đối với sản phẩm Bảo hiểm trách nhiệm dân sự bắt buộc người ngồi trên xe máy) hoặc Quy tắc Bảo hiểm tự nguyện xe cơ giới ban hành theo quyết định số 0246/2015/QĐ-GIC-XCG ngày 12/03/2015 của Tổng giám đốc Tổng Công ty Cổ phần Bảo hiểm Toàn Cầu (đối với sản phẩm Bảo hiểm tai nạn người ngồi trên xe máy)',
    'styleVersion': 'V2'
  },
  'config': {
    'labelWidth': 200
  }
}

export const disbursed = {
  'schema': {
    'type': 'object',
    'title': 'create form',
    'description': '',
    'properties': {},
    'required': []
  },
  'columns': [
    {
      'name': '',
      'schema': {
        'type': 'object',
        'description': '自定义对象组',
        'properties': {},
        'required': []
      },
      'fieldJson': {
        'noteStyle': {},
        'tipTitleStyle': {},
        'tipContentStyle': {},
        'extraConfig': {},
        'required': true,
        'show': true,
        'tipShowType': 'auto',
        'isCustom': true,
        'ocrSwitch': []
      },
      'renderType': 'Object',
      'columns': [
        {
          'name': 'Disbursed Time',
          'schema': {
            'type': 'integer',
            'description': '自定义日期'
          },
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'minDate': '',
            'maxDate': '',
            'minDateUnit': 1,
            'maxDateUnit': 1,
            'dateType': 'date',
            'dateStartUnit': 1,
            'defaultValueSource': 1,
            'displayRelyGather': [],
            'defaultValueOriginParams': [],
            'defaultValueOriginCfg': {
              'method': 'POST'
            },
            'isCustom': true,
            'extendDisplayCfg': [],
            'limit': false,
            'dateDefaultTime': '00:00:00',
            'valueDateType': 'timestamp',
            'autoLinking': false
          },
          'renderType': 'Date',
          'key': 'disbursedTime',
          'renderKey': '1733471309742_43117',
          'nameLocal': 'Disbursed Time'
        }
      ],
      'key': 'container',
      'renderKey': '1733397163794_83306'
    },
    {
      'name': '',
      'key': 'additionalInfo',
      'schema': {
        'type': 'object',
        'description': '补充信息',
        'properties': {},
        'required': []
      },
      'renderType': 'Object',
      'columns': [
        {
          'name': '',
          'nameLocal': '',
          'key': 'attachments',
          'schema': {
            'type': 'array',
            'description': '附件集合'
          },
          'columns': [
            {
              'name': '',
              'nameLocal': '',
              'schema': {
                'type': 'object',
                'description': '附件',
                'properties': {},
                'required': []
              },
              'renderType': 'Object',
              'fieldJson': {
                'noteStyle': {},
                'tipTitleStyle': {},
                'tipContentStyle': {},
                'extraConfig': {},
                'required': true,
                'show': true,
                'tipShowType': 'auto',
                'isArrayItem': true,
                'ocrSwitch': []
              },
              'columns': [
                {
                  'name': 'Contract',
                  'schema': {
                    'type': 'array',
                    'minItems': 1,
                    'description': '自定义上传控件'
                  },
                  'fieldJson': {
                    'noteStyle': {},
                    'tipTitleStyle': {},
                    'tipContentStyle': {},
                    'extraConfig': {},
                    'required': true,
                    'show': true,
                    'tipShowType': 'auto',
                    'maxCount': '',
                    'uploadTarget': 'oss',
                    'uploadSource': 3,
                    'defaultValueSource': 1,
                    'displayRelyGather': [],
                    'defaultValueOriginParams': [],
                    'defaultValueOriginCfg': {
                      'method': 'POST'
                    },
                    'isCustom': true,
                    'extendDisplayCfg': [],
                    'uploadType': [
                      'pdf',
                      'img'
                    ],
                    'contentCutting': false,
                    'cuttingContentFields': []
                  },
                  'renderType': 'Upload',
                  'key': 'contracts',
                  'renderKey': '1733449949214_83241',
                  'nameLocal': 'Contract'
                }
              ],
              'key': '1733449507085_21402',
              'renderKey': '1733449507085_21402'
            }
          ],
          'renderType': 'Array',
          'renderKey': '1733449507085_98504',
          'fieldJson': {
            'noteStyle': {},
            'tipTitleStyle': {},
            'tipContentStyle': {},
            'extraConfig': {},
            'required': true,
            'show': true,
            'tipShowType': 'auto',
            'repeat': false,
            'ocrSwitch': []
          }
        }
      ],
      'renderKey': '1733449507085_72728',
      'fieldJson': {
        'noteStyle': {},
        'tipTitleStyle': {},
        'tipContentStyle': {},
        'extraConfig': {},
        'required': true,
        'show': true,
        'tipShowType': 'auto',
        'ocrSwitch': []
      }
    }
  ],
  'fieldJson': {
    'version': '2.7.0',
    'hasClause': true,
    'clauseContent': 'I agree to the agreement that GIC is only liable in accordance with the terms & conditions and exclusions of Circular No. 22/2016 / TT-BTC by Ministry of Finance for Compulsory Insurance Civil liability of motor vehicle owners (for products Compulsory civil liability insurance for passengers on motorbike) or Voluntary Insurance Rules of motor vehicle issued by decision No. 0246/2015 / QD-GIC-XCG dated March 12, 2015 by General Director of GIC (for personal accident insurance product on motorbike)',
    'clauseContentLocal': 'Tôi đồng ý và thoả thuận rằng, Bảo Hiểm Toàn Cầu chỉ chịu trách nhiệm phù hợp với những điều khoản, điều kiện và điểm loại trừ của Thông tư số 22/2016/TT-BTC quy định Quy tắc Bảo hiểm Bắt buộc Trách nhiệm dân sự của chủ xe cơ giới của Bộ Tài chính (đối với sản phẩm Bảo hiểm trách nhiệm dân sự bắt buộc người ngồi trên xe máy) hoặc Quy tắc Bảo hiểm tự nguyện xe cơ giới ban hành theo quyết định số 0246/2015/QĐ-GIC-XCG ngày 12/03/2015 của Tổng giám đốc Tổng Công ty Cổ phần Bảo hiểm Toàn Cầu (đối với sản phẩm Bảo hiểm tai nạn người ngồi trên xe máy)',
    'styleVersion': 'V2'
  },
  'config': {
    'labelWidth': 200
  }
}
