import request from '@/utils/request2'
import Dao from '@/utils/dao'

function returnHeader() {
  return {
    'x-5a-temp-token': Dao.get('tempToken')
  }
}

export function loanPage(query) {
  return request({
    url: '/pro/refinance/temp/loanPage',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function refinancingApprove(query) {
  return request({
    url: '/pro/refinance/temp/loan/approved',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function refinancingDecline(query) {
  return request({
    url: '/pro/refinance/temp/loan/decline',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function refinancingDisbursed(query) {
  return request({
    url: '/pro/refinance/temp/loan/disbursed',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}
