<template>
  <div class="refinancing">
    <div class="header">
      <img
        class="logo"
        height="35%"
        :src="require('@/assets/imgs/logo.png')"
      >
    </div>
    <!-- <Lang @changeLang="changeLang" /> -->

    <div v-if="autoMortgageStatus == 2" class="container">
      <FuSwitch v-model="active" @change="handleChange" />
      <div v-if="active == '1'" class="form-container">
        <FuFormMin
          v-if="showFuFormMin"
          ref="review"
          v-model="formData"
          :schema="formApproveJSON"
          :lang="lang"
        />
      </div>
      <div v-if="active == '2'" class="form-container">
        <FuFormMin
          v-if="showFuFormMin"
          ref="review"
          v-model="formData"
          :schema="formDeclineJSON"
          :lang="lang"
        />
      </div>
    </div>

    <div v-else-if="autoMortgageStatus == 3">
      <FuFormMin
        v-if="showFuFormMin"
        ref="review"
        v-model="formData"
        :schema="formDisbursedJSON"
        :lang="lang"
      />
    </div>

    <div class="button-group">
      <Button
        class="next-btn"
        type="primary"
        color="#d9000b"
        block
        @click="handleSubmit"
      >
        {{ $t('refinancing.Submit') }}
      </Button>
    </div>

    <div v-if="showSuccess" class="success-dev">
      <img :src="SuccessIcon" class="img">
      <p>{{ $t('refinancing.success') }}</p>
    </div>
  </div>

</template>

<script>
import { Button, Dialog } from 'vant'
import FuSwitch from './components/Switch'
import FuFormMin from '@/components/FuFormMin'
import CryptoJS from 'crypto-js'
// import Lang from './components/lang/index.vue'
import { toastLoading } from '@/utils/index'
import { approve, decline, disbursed } from './config/index.js'
import Dao from '@/utils/dao'
import { loanPage, refinancingApprove, refinancingDecline, refinancingDisbursed } from './api/index.js'
import SuccessIcon from '@/assets/imgs/success-icon.png'

export default {
  components: { FuFormMin, FuSwitch, Button
    // Lang,
  },
  props: {},
  data() {
    return {
      lang: 'en',
      active: '1',
      loading: true,
      showFuFormMin: false,
      autoMortgageStatus: '',
      formData: {},
      // formApproveData: {},
      // formDeclineData: {},
      // formDisbursedData: {},
      formApproveJSON: {},
      formDeclineJSON: {},
      formDisbursedJSON: {},
      routeQuery: {},
      callMethodApprove: refinancingApprove,
      callMethodDecline: refinancingDecline,
      callMethodDisbursed: refinancingDisbursed,
      showSuccess: false,
      SuccessIcon
    }
  },
  mounted() {
    this.initParams()
    this.init()
  },
  methods: {
    changeLang(val) {
      if (this.hasInit) {
        this.lang = val
        this.init()
      }
    },
    handleChange(e) {
      if (this.active == e) return
      this.active = '3'
      // this.formApproveData = {}
      // this.formDeclineData = {}
      this.formData = {}
      setTimeout(() => {
        this.active = e
      }, 50)
    },
    decryptByAES(cipherText, keyInBase64Str = '22289A80B2CC11EF86BA0C42A1B87106') {
      const key = CryptoJS.enc.Base64.parse(keyInBase64Str)
      // 返回的是一个Word Array Object，其实就是Java里的字节数组
      const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })

      return decrypted.toString(CryptoJS.enc.Utf8)
    },
    initParams() {
      const { tempVisitToken: tempToken, loanCode } = this.$route.query
      this.routeQuery = {
        // loanCode: 'RFN-20241130-081200003114924', // TODO:
        loanCode: this.decryptByAES(loanCode),
        tempToken: this.decryptByAES(tempToken)
        // tempToken: 'eyJhbGciOiJIUzI1NiIsInppcCI6IkRFRiJ9.eNpsjsFqwzAMht9F5wbkWJad3hLYoJed1p0MIa49cEeTEntjo_Td55gdNphu_yfx6b9Benewhxwu15eYYn5e3sIMuwrGj42MKcw-rOM5LXO5vFk4fR2jt7AXiEJyu7NQo4UO6wgLhW2GYwrr4d_dPM358CNBZr6Xn-HzWrJpmUlJUn9K5K3Xrw6vy3p5Wqr5QXWdHgb1KDQRYW-kIdkjqYEk9T1b2ORxykWuZYtSV_k5x6IKxvgTOtM4b1xDTNhMrLjRooDWsUbl4f4NAAD__w.Eo5hcO3crLmir7lNqYqueUYyf6Te-ebL8Yth13Qub4E'
      }
      console.log(this.routeQuery, tempToken, loanCode)
      Dao.set('tempToken', this.routeQuery.tempToken)
    },
    async init() {
      this.loading = toastLoading()

      this.formApproveJSON = approve
      this.formDeclineJSON = decline
      this.formDisbursedJSON = disbursed

      const res = await loanPage({ loanCode: this.routeQuery.loanCode })
      this.$nextTick(() => {
        this.loading && this.loading.clear()
        this.loading = null
      })
      this.autoMortgageStatus = res.autoMortgageStatus
      if (res.autoMortgageStatus != 2 && res.autoMortgageStatus != 3) {
        this.$router.push({ name: 'refinancingInvalid' })
        return
      }

      this.showFuFormMin = true
    },
    getDialogMessage() {
      return `
        <i class="van-icon van-icon-warning-o" style="margin-bottom: 25px; color: #C82A21; font-size: 65px; display: block;"></i>
        <p class="tit" style="font-weight: 900; font-size: 14px; color: #C82A21;">${this.$t(`refinancing.confirm`)} ${this.$t(`refinancing.${this.getCallMethod()}`)}</p>
      `
    },
    getFormData() {
      const { container, additionalInfo } = this.formData
      const { attachments } = additionalInfo
      const contractFileKey = attachments[0].contracts[0]
      const params = {
        ...container,
        contractFileKey,
        loanCode: this.routeQuery.loanCode
      }
      return params
    },
    getCallMethod() {
      // console.log(this.autoMortgageStatus, this.active)
      if (this.autoMortgageStatus == 3) return 'Disbursed'
      if (this.active == 1) {
        return 'Approve'
      } else {
        return 'Decline'
      }
    },
    async valid() {
      // const _this = this
      return new Promise(resolve => {
        this.$refs.review.validate().then(res => {
          resolve(true)
        }).catch(() => {
          resolve(false)
        })
      })
    },
    async handleSubmit() {
      console.log(this.formData)
      if (!await this.valid()) return
      Dialog.confirm({
        message: this.getDialogMessage(),
        theme: 'round-button',
        confirmButtonText: this.$t('yes'),
        // confirmButtonColor: '#C82A21',
        showCancelButton: true,
        cancelButtonText: this.$t('no'),
        // cancelButtonColor: '#fff',
        beforeClose: this.beforeClose,
        className: 'inspection-dialog'
      })
    },
    async beforeClose(action, done) {
      // 取消 这个设计稿是把确认放在左边
      // 所以组件的confirm 相当于取消
      // console.log(action, done)
      if (action !== 'confirm') {
        this.type = ''
        done()
        return
      }
      const callMethod = this.getCallMethod()
      const params = this.getFormData()
      const res = await this[`callMethod${callMethod}`](params)
      if (res) {
        done()
        this.showSuccess = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  padding: 0 20px;
  height: 100px;
  line-height: 75px;
  background-color: #D90109;
  font-size: 0;
  text-align: center;
}
.button-group {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  .next-btn {
    margin: 20px 5px;
  }
}

.inspection-dialog {
  .van-dialog__footer {
    justify-content: space-between;
  }
  .van-dialog__message {
    white-space: pre-line;
  }
  .van-dialog__cancel,
  .van-dialog__confirm {
    width: 130px;
    border: 1.5px solid #C82A21;
    box-shadow: 0px .35px 1.4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    background: none;
  }
  .van-dialog__cancel {
    background-color: #FFFFFF;
    color: #C82A21;
  }
  .van-dialog__confirm {
    margin-left: 10px;
    background-color: #C82A21;
  }
}
.container {
  max-width: 800px;
  margin: 0 auto;
}
.form-container {
  overflow: scroll;
  padding-bottom: 100px;
}

.success-dev {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  z-index: 999;
  text-align: center;
  img {
    margin-top: 5rem;
  }
  p {
    margin-top: 50px;
    font-size: 30px;
    color: #67d27e;
  }
}
</style>
